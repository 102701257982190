import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
// const Register = React.lazy(() => import('../pages/auth/Register'));
// const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));

// landing
// const Landing = React.lazy(() => import('../pages/landing/'));

// dashboard
const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce/'));
// const AnalyticsDashboard = React.lazy(() => import('../pages/dashboard/Analytics/'));

const experienceCenter = React.lazy(() => import('../pages/experienceCenter'));
const digitalShadeCard = React.lazy(() => import('../pages/digitalShadeCard'));
const privateDigitalShadeCard = React.lazy(() => import('../pages/privateDigitalShadeCard'));

const jobTitles = React.lazy(() => import('../pages/career/jobTitles'));
const submissions = React.lazy(() => import('../pages/career/submissions'));

const misLinks = React.lazy(() => import('../pages/mis'));

const investorRelation = React.lazy(() => import('../pages/investorRelation'));

const contactUs = React.lazy(() => import('../pages/contactUs'));
const createYourOwn = React.lazy(() => import('../pages/createYourOwn'));

const newsAndEvent = React.lazy(() => import('../pages/newsAndEvent'));
const testimonials = React.lazy(() => import('../pages/testimonials'));
const chatSetup = React.lazy(() => import('../pages/chatFormSetup'));
const chatSubmissions = React.lazy(() => import('../pages/chatFormSetup/submissions'));

// Contest
const UserList = React.lazy(() => import('../pages/User'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/dashboard',
    name: 'Dashboards',
    icon: 'airplay',
    header: 'Navigation',
    component: EcommerceDashboard,
    route: PrivateRoute,
};
const usersRoutes: RoutesProps = {
    path: '/users',
    name: 'Users',
    icon: 'airplay',
    header: 'Navigation',
    component: UserList,
    route: PrivateRoute,
};

const experienceCenterAppRoutes: RoutesProps = {
    path: '/experience-center',
    name: 'Experience Center',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'users',
    component: experienceCenter,
    header: 'Apps',
};
const digitalShadeCardRoutes: RoutesProps = {
    path: '/digital-shade-card',
    name: 'Digital Shade Card',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'layers',
    component: digitalShadeCard,
    header: 'Apps',
};
const privateDigitalShadeCardRoutes: RoutesProps = {
    path: '/private-digital-shade-card',
    name: 'Private Digital Shade Card',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'shield',
    component: privateDigitalShadeCard,
    header: 'Apps',
};

const careerRoutes: RoutesProps = {
    path: '/career',
    name: 'Career',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'paperclip',
    children: [
        {
            path: '/career/job-titles',
            name: 'Job titles',
            component: jobTitles,
            route: PrivateRoute,
        },
        {
            path: '/career/submissions',
            name: 'Submissions',
            component: submissions,
            route: PrivateRoute,
        },
    ],
};
const misDashboardRoutes: RoutesProps = {
    path: '/sales-kit',
    name: 'Sales Kit',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'file-text',
    component: misLinks,
    header: 'Apps',
};
const investorRelationRoutes: RoutesProps = {
    path: '/investor-relation',
    name: 'Investor Relation',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'file-text',
    component: investorRelation,
    header: 'Apps',
};
const contactUsRoutes: RoutesProps = {
    path: '/contact-us',
    name: 'Contact us',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'message-square',
    component: contactUs,
    header: 'Apps',
};
const createYourOwnRoutes: RoutesProps = {
    path: '/create-your-own',
    name: 'Create your own',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'grid',
    component: createYourOwn,
    header: 'Apps',
};
const newsAndEventRoutes: RoutesProps = {
    path: '/news-and-events',
    name: 'News and Events',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'tv',
    component: newsAndEvent,
    header: 'Apps',
};
const testimonialsRoutes: RoutesProps = {
    path: '/testimonials',
    name: 'Testimonials',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'tv',
    component: testimonials,
    header: 'Apps',
};

const chatsRoutes: RoutesProps = {
    path: '/chat',
    name: 'Chat Setup',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'message-square',
    children: [
        {
            path: '/chat/setup',
            name: 'Setup',
            component: chatSetup,
            route: PrivateRoute,
        },
        {
            path: '/chat/submissions',
            name: 'Submissions',
            component: chatSubmissions,
            route: PrivateRoute,
        },
    ],
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];



// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, usersRoutes, experienceCenterAppRoutes, digitalShadeCardRoutes, privateDigitalShadeCardRoutes, careerRoutes, misDashboardRoutes, investorRelationRoutes, contactUsRoutes, createYourOwnRoutes, newsAndEventRoutes, testimonialsRoutes, chatsRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
