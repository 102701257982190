import { APICore } from './apiCore';
const api = new APICore();

// MisLink
function misLinks(params: {
    status: string,
    search: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/mis-links`;
    return api.get(`${baseUrl}`, params);

}
function addMisLink(params: {
    title: string,
    user: string,
    link: string,
    status: string,
}) {
    const baseUrl = `/mis-links`;
    return api.create(`${baseUrl}`, params);
}

function updateMisLink(params: {
    status: string,
    link: string,
    title: string,
    user: string
}, id: string) {
    const baseUrl = `/mis-links/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteMisLink(id: string) {
    const baseUrl = `/mis-links/${id}`;
    return api.delete(`${baseUrl}`);
}

function showMisLink(params: {}, id: string) {
    const baseUrl = `/mis-links/${id}`;
    return api.get(`${baseUrl}`, params);
}

export {
    misLinks,
    addMisLink,
    updateMisLink,
    deleteMisLink,
    showMisLink
};
