import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    misLinks as misLinksApi,
    addMisLink as addMisLinkApi,
    updateMisLink as updateMisLinkApi,
    showMisLink as showMisLinkApi,
    deleteMisLink as deleteMisLinkApi,
} from '../../helpers';

// actions
import { misLinkApiResponseSuccess, misLinkApiResponseError } from './actions';

// constants
import { MisLinkActionTypes } from './constants';

interface MisLinkData {
    payload: {
        search: string;
        description: string;
        sortBy: string,
        limit: number,
        page: number,
        //
        id: string,
        status: string,
        title: string,
        user: string,
        link: string,
        shortContent: string
        content: string
    };
    type: string;
}

/**
 * MisLink list
 */
function* misLinkList({ payload: {
    search,
    status,
    sortBy,
    limit,
    page
} }: MisLinkData): SagaIterator {
    try {

        const response = yield call(misLinksApi, {
            search,
            status,
            sortBy,
            limit,
            page
        });
        const responseData = {
            users: response.data.users,
            data: response.data.results,
            page: response.data.page,
            limit: response.data.limit,
            totalPages: response.data.totalPages,
            totalResults: response.data.totalResults,
        }
        yield put(misLinkApiResponseSuccess({
            actionType: MisLinkActionTypes.MIS_LINK_LIST,
            responseData
        }));
    } catch (error: any) {
        yield put(misLinkApiResponseError(MisLinkActionTypes.MIS_LINK_LIST, error));

    }
}

/**
 * Add MisLink
 */
function* addMisLink({ payload: {
    title,
    user,
    link,
    status } }: MisLinkData): SagaIterator {
    try {

        const response = yield call(addMisLinkApi, {
            title,
            user,
            link,
            status
        });
        const responseData = {
            data: response.data.data,
        }
        yield put(misLinkApiResponseSuccess({
            actionType: MisLinkActionTypes.ADD_MIS_LINK,
            responseData
        }

        ));
    } catch (error: any) {
        yield put(misLinkApiResponseError(MisLinkActionTypes.ADD_MIS_LINK, error));

    }
}

/**
 * Show User
 */
function* showMisLink({ payload: {
    id,
} }: MisLinkData): SagaIterator {
    try {
        const response = yield call(showMisLinkApi, {}, id);
        const responseData = {
            data: response.data,
        }
        yield put(misLinkApiResponseSuccess({
            actionType: MisLinkActionTypes.SHOW_MIS_LINK,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(misLinkApiResponseError(MisLinkActionTypes.SHOW_MIS_LINK, error));

    }
}

/**
 * Update MisLink
 */
function* updateMisLink({ payload: {
    id,
    status,
    title,
    user,
    link } }: MisLinkData): SagaIterator {
    try {

        const response = yield call(updateMisLinkApi, {
            status,
            title,
            user,
            link,
        }, id);
        const responseData = {
            data: response.data.data,
        }
        yield put(misLinkApiResponseSuccess({
            actionType: MisLinkActionTypes.UPDATE_MIS_LINK,
            responseData
        }
        ));
    } catch (error: any) {
        yield put(misLinkApiResponseError(MisLinkActionTypes.UPDATE_MIS_LINK, error));

    }
}
/**
 * Delete MisLink
 */
function* deleteMisLink({ payload: { id } }: MisLinkData): SagaIterator {
    try {
        yield call(deleteMisLinkApi, id);
        const responseData = {
            deletedId: id
        };
        yield put(misLinkApiResponseSuccess({
            actionType: MisLinkActionTypes.DELETE_MIS_LINK,
            responseData
        }));
    } catch (error: any) {
        yield put(misLinkApiResponseError(MisLinkActionTypes.DELETE_MIS_LINK, error));

    }
}



export function* watchMisLinkList() {
    yield takeEvery(MisLinkActionTypes.MIS_LINK_LIST, misLinkList);
}

export function* watchAddMisLink() {
    yield takeEvery(MisLinkActionTypes.ADD_MIS_LINK, addMisLink);
}

export function* watchUpdateMisLink() {
    yield takeEvery(MisLinkActionTypes.UPDATE_MIS_LINK, updateMisLink);
}
export function* watchShowMisLink() {
    yield takeEvery(MisLinkActionTypes.SHOW_MIS_LINK, showMisLink);
}

export function* watchDeleteMisLink() {
    yield takeEvery(MisLinkActionTypes.DELETE_MIS_LINK, deleteMisLink);
}



function* misLinkSaga() {
    yield all([fork(watchMisLinkList), fork(watchAddMisLink), fork(watchShowMisLink), fork(watchUpdateMisLink), fork(watchDeleteMisLink)]);
}

export default misLinkSaga;
