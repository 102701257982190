// constants
import { MisLinkActionTypes } from './constants';

const INIT_STATE = {
    users: [],
    misLinks: [],
    misLink: {},
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    isDeleted: false,
};

interface MisLinkActionType {
    type:
    | MisLinkActionTypes.API_RESPONSE_SUCCESS
    | MisLinkActionTypes.API_RESPONSE_ERROR
    | MisLinkActionTypes.MIS_LINK_LIST
    | MisLinkActionTypes.ADD_MIS_LINK
    | MisLinkActionTypes.SHOW_MIS_LINK
    | MisLinkActionTypes.UPDATE_MIS_LINK

    payload: {
        actionType?: string;
        responseData?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        isDeleted: boolean,
    };
}

interface State {
    users: any;
    misLinks: any;
    misLink: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const MisLink = (state: State = INIT_STATE, action: MisLinkActionType): any => {
    switch (action.type) {
        case MisLinkActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload?.actionType) {
                case MisLinkActionTypes.MIS_LINK_LIST: {                    
                    return {
                        ...state,
                        misLinks: action.payload?.responseData?.data,
                        users: action.payload?.responseData?.users,
                        page: action.payload?.responseData?.page,
                        limit: action.payload?.responseData?.limit,
                        totalPages: action.payload?.responseData?.totalPages,
                        totalResults: action.payload?.responseData?.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case MisLinkActionTypes.ADD_MIS_LINK: {
                    return {
                        ...state,
                        misLinks: [...state.misLinks, action.payload?.responseData?.data],
                        totalResults: (action.payload?.responseData?.totalResults ? (action.payload?.responseData?.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case MisLinkActionTypes.SHOW_MIS_LINK: {
                    return {
                        ...state,
                        misLink: action.payload?.responseData?.data,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case MisLinkActionTypes.UPDATE_MIS_LINK: {
                    const updatedMisLink = action.payload?.responseData?.data;
                    const allSubs = state.misLinks;
                    const updatedSubs = allSubs.map((cat: any) => cat.id === updatedMisLink.id ? updatedMisLink : cat);
                    return {
                        ...state,
                        misLinks: updatedSubs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case MisLinkActionTypes.DELETE_MIS_LINK: {
                    const deletedId = action.payload?.responseData?.deletedId;
                    const allSubs = state.misLinks;
                    const updatedSubs = allSubs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        misLinks: updatedSubs,
                        loading: true,
                        hasErrors: false,
                        submitted: false,
                        isDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case MisLinkActionTypes.API_RESPONSE_ERROR:
            switch (action.payload?.actionType) {
                case MisLinkActionTypes.MIS_LINK_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case MisLinkActionTypes.ADD_MIS_LINK: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case MisLinkActionTypes.SHOW_MIS_LINK: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case MisLinkActionTypes.UPDATE_MIS_LINK: {
                    return {
                        ...state,
                        error: action.payload?.error,
                        validationError: action.payload?.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default MisLink;
