// constants
import { MisLinkActionTypes } from './constants';

export interface MisLinkActionType {
    type:
    | MisLinkActionTypes.API_RESPONSE_SUCCESS
    | MisLinkActionTypes.API_RESPONSE_ERROR
    | MisLinkActionTypes.MIS_LINK_LIST
    | MisLinkActionTypes.ADD_MIS_LINK
    | MisLinkActionTypes.UPDATE_MIS_LINK
    | MisLinkActionTypes.SHOW_MIS_LINK
    | MisLinkActionTypes.DELETE_MIS_LINK
    payload: {} | string;
}

interface FiltersData {
    status: string,
    sortBy: string,
    limit: number,
    page: number,
    search: string
};

interface FormData {
    status: string,
    id: string,
    title: string,
    user: string,
    link: string
};


interface ResponseData {
    actionType: string,
    responseData: any,
}


// common success
export const misLinkApiResponseSuccess = ({ actionType, responseData }: ResponseData): MisLinkActionType => ({
    type: MisLinkActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, responseData },
});
// common error
export const misLinkApiResponseError = (actionType: string, error: string): MisLinkActionType => ({
    type: MisLinkActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createMisLink = ({
    title,
    user,
    link,
    status
}: FormData): MisLinkActionType => ({
    type: MisLinkActionTypes.ADD_MIS_LINK,
    payload: {
        title,
        user,
        link,
        status
    },
});


export const misLinkList = ({
    status,
    sortBy,
    limit,
    page,
    search,
}: FiltersData): MisLinkActionType => ({
    type: MisLinkActionTypes.MIS_LINK_LIST,
    payload: {
        search,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showMisLink = (id: string): MisLinkActionType => ({
    type: MisLinkActionTypes.SHOW_MIS_LINK,
    payload: { id },
});

export const destroyMisLink = (id: string): MisLinkActionType => ({
    type: MisLinkActionTypes.DELETE_MIS_LINK,
    payload: { id },
});

export const updateMisLink = ({
    id,
    title,
    user,
    link,
    status
}: FormData): MisLinkActionType => ({
    type: MisLinkActionTypes.UPDATE_MIS_LINK,
    payload: {
        id,
        title,
        user,
        link,
        status
    },
});



