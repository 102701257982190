export enum TestimonialsActionTypes {
    API_RESPONSE_SUCCESS = '@@testimonials/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@testimonials/API_RESPONSE_ERROR',
    TESTIMONIALS_LIST = '@@testimonials/TESTIMONIALS_LIST',
    ADD_TESTIMONIALS = '@@testimonials/ADD_TESTIMONIALS',
    SHOW_TESTIMONIALS = '@@testimonials/SHOW_TESTIMONIALS',
    UPDATE_TESTIMONIALS = '@@testimonials/UPDATE_TESTIMONIALS',
    DELETE_TESTIMONIALS = '@@testimonials/DELETE_TESTIMONIALS',
}

